import {Box, Flex, Text, Table} from "gestalt"
import {useEffect, useState} from "react";
import {directus} from "../utils/directus";
import {NewsData, NewsItem} from "../../@types";
import {format} from "date-fns";

export default function News() {
    const [news, updateNews] = useState<NewsData | null>(null)
    useEffect(() => {
        directus.items('news').readByQuery({
            limit: 25,
            meta: '*',
            page: 1
        }).then((data) => {
            // @ts-ignore
            updateNews(data)
        })
    }, [])
    return (
        <Box padding={5}>
            <Flex direction={'column'}>
                {/*<Text size="500">Новости</Text>*/}
                {news && (
                    <Table accessibilityLabel="Main example table">
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>
                                    <Text weight="bold">Название</Text>
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    <Text weight="bold">Дата создания</Text>
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {news.data.map((item: NewsItem) => (
                                <Table.Row key={item.id}>
                                    <Table.Cell>
                                        <Text>{item.title}</Text>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <Text>{format(new Date(), 'dd MMMM yyyy')}</Text>
                                    </Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                )}
            </Flex>
        </Box>
    )
}