export default {
    menu: [
        // {
        //     label: 'Новости',
        //     id: 'news'
        // },
        {
            label: 'Требы',
            id: 'treby'
        },
        // {
        //     label: 'Медиа',
        //     id: 'media'
        // },
        {
            label: 'Расписание',
            id: 'events'
        }
    ]
}
