import React, {useEffect, useState} from 'react';
import {Button, Flex, Text, TextField, SlimBanner} from "gestalt";
import {useForm, SubmitHandler} from "react-hook-form";
import {directus} from "../utils/directus";
import {Navigate} from "react-router-dom";

type Inputs = {
    email: string
    password: string
};

const Auth = () => {
    const {handleSubmit, formState: {errors}, setValue} = useForm<Inputs>();
    const [authError, updateAuthError] = useState<boolean>(false)
    const [isAuth, updateIsAuth] = useState<boolean>(false)

    const getUserInfo = async () => {
        await directus.users.me.read().catch(() => {
            updateIsAuth(true)
        })
    }

    const onSubmit: SubmitHandler<Inputs> = async (data) => {
        if (data.email && data.password)
            await directus.auth.login({
                email: data.email,
                password: data.password
            }).then(() => {
                updateIsAuth(true)
            }).catch(() => {
                updateAuthError(true)
            })
    };

//    useEffect(() => {
//        getUserInfo()
//    }, [])

    return (
        <Flex justifyContent={'center'} alignItems={'center'} minHeight={'80vh'}>
            <Flex direction={'column'} gap={5} width={400}>
                <Text size={"500"}>Войти</Text>
                {authError && <SlimBanner
                    dismissButton={{
                        accessibilityLabel: 'Dismiss banner',
                        onDismiss: () => {
                            updateAuthError(false)
                        },
                    }}
                    iconAccessibilityLabel="Ошибка"
                    message="Не правильный email или пароль"
                    type="error"
                />}
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Flex direction={'column'} gap={5}>
                        <TextField size={'lg'} label={'Email'} autoComplete={'email'}
                                   id={'email'} onChange={({value}) => setValue('email', value)}/>
                        <TextField size={'lg'} label={'Пароль'} hasError={false} id={'password'}
                                   onChange={({value}) => setValue('password', value)}
                                   type={"password"}/>
                        <Button size={'lg'} text={'Войти'} type={"submit"} role={"button"} fullWidth color={'red'}/>
                    </Flex>
                </form>
                {isAuth && <Navigate to={'/treby'}/>}
            </Flex>
        </Flex>
    );
};

export default Auth;
