import React, {useEffect, useState} from 'react';
import 'gestalt/dist/gestalt.css';
import 'gestalt-datepicker/dist/gestalt-datepicker.css'
import Navigation from './components/Navigation';
import {BrowserRouter, Routes, Route, Navigate} from 'react-router-dom';
import News from './pages/News';
import Auth from "./pages/Auth";
import {directus} from "./utils/directus";
import Treby from "./pages/Treby";
import {setDefaultOptions} from "date-fns";
import {ru} from "date-fns/locale";
import PrivateRoutes from "./pages/PrivateRoutes";
import Home from "./pages/Home";
import Events from "./pages/Events";
import {GlobalEventsHandlerProvider} from "gestalt";

setDefaultOptions({
    locale: ru
})

function App() {
    const [isAuth, updateIsAuth] = useState<boolean>(true)

    const getUserInfo = async () => {
        await directus.users.me.read().catch(() => {
            updateIsAuth(false)
        })
    }

//    useEffect(() => {
//        getUserInfo()
//    }, [])

    return (
        <GlobalEventsHandlerProvider>
            <BrowserRouter>
                <main>
                    <Navigation/>
                    {!isAuth && <Navigate to={'/auth'}/>}
                    <Routes>
                        <Route element={<PrivateRoutes/>}>
                            <Route path="/" element={<Home/>}/>
                            <Route path="/news" element={<News/>}/>
                            <Route path="/treby" element={<Treby/>}/>
                            <Route path="/events" element={<Events/>}/>
                            <Route path="*" element={<Home/>}/>
                        </Route>
                        <Route index path="/auth" element={<Auth/>}/>
                    </Routes>
                </main>
            </BrowserRouter>
        </GlobalEventsHandlerProvider>
    );
}

export default App;
