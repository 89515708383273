import {useEffect, useState} from 'react';
import {Flex, Tabs, Avatar, Box, Button} from 'gestalt';
import Logo from '../assets/donskoy_main_logo.svg'
import data from '../data';
import {useLocation, redirect} from "react-router-dom";
import {findIndex} from "lodash";
import {directus} from "../utils/directus";

type MenuItem = {
    href: string
    text: string
}

export default function Navigation() {
    const [activeIndex, setActiveIndex] = useState(0);
    const [menu, updateMenu] = useState<MenuItem[]>([])
    const location = useLocation()

    useEffect(() => {
        const menu = data.menu.map(item => {
            return {
                href: item.id,
                text: item.label
            }
        })
        updateMenu(menu)
        const page = findIndex(menu, {href: location.pathname.slice(1)})
        setActiveIndex(page)
    }, [])

    const exitAccount = async () => {
        await directus.auth.logout()
            .then(() => {
             window.location.reload()
        })
    }

    return (
        <Flex height="100%" alignItems="center" justifyContent="between">
            <Flex width={'20%'}>
                <img src={Logo} width={200} alt={'Донской Монастырь'}/>
            </Flex>
            <Flex wrap>
                <Tabs
                    activeTabIndex={activeIndex}
                    onChange={({activeTabIndex}) => setActiveIndex(activeTabIndex)}
                    tabs={menu}
                    wrap
                />
            </Flex>
            <Flex width={'20%'} alignItems='center' justifyContent='end'>
                <Box padding={5}>
                    {/*<Avatar*/}
                    {/*    size="md"*/}
                    {/*    src="https://i.ibb.co/ZfCZrY8/keerthi.jpg"*/}
                    {/*    name="Keerthi"*/}
                    {/*    verified*/}
                    {/*/>*/}
                    <Button color={'red'} text={'Выйти'} onClick={exitAccount}/>
                </Box>
            </Flex>
        </Flex>
    );
}
