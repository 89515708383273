import { Directus } from '@directus/sdk';

const directus = new Directus(process.env.DIRECTUS_URL || 'https://api.donskoi.org', {
    auth: {
        autoRefresh: true,
        mode: 'json'
    }
});

export {
    directus
}