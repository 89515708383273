import React, {useEffect, useState} from 'react';
import {
    Box,
    Heading,
    Text,
    Flex,
    Button,
    OverlayPanel,
    FixedZIndex,
    CompositeZIndex,
    Layer,
    TextArea,
    TextField
} from "gestalt";
import {directus} from "../utils/directus";
import {format, parseISO, setHours, startOfDay} from "date-fns";
import {
    DatePicker, LocaleData
} from "gestalt-datepicker";
import {useForm, SubmitHandler, Controller} from "react-hook-form";
import {ru} from "date-fns/locale";

type Event = {
    id: string
    date: string
    list: string
}

type EditForm = {
    date: string
    list: string
}

const Events = () => {
    const [events, updateEvents] = useState<Event[]>([])
    const [panelOpen, updatePanelOpen] = useState<boolean>(false)
    const [panelOpenCreate, updatePanelOpenCreate] = useState<boolean>(false)
    const [event, updateEvent] = useState<Event>()
    const {handleSubmit, setValue, control, reset} = useForm<EditForm>();
    const {
        handleSubmit: handleSubmitCreate,
        control: controlCreate,
        reset: resetCreate
    } = useForm<EditForm>();

    const onSubmitCreateForm: SubmitHandler<EditForm> = async (data) => {
        if (data.date && data.list) {
            await directus.items('events').createOne({
                date: data.date,
                list: data.list,
                status: 'published'
            }).then(() => {
                updatePanelOpenCreate(false)
                resetCreate()
                getEvents()
            })
        }
    }
    const onSubmitEditForm: SubmitHandler<EditForm> = async (data) => {
        if (data.date && data.list && event?.id) {
            await directus.items('events').updateOne(event.id, {
                date: data.date,
                list: data.list
            }).then(() => {
                updatePanelOpen(false)
                reset()
                getEvents()
            })
        }
    };

    const HEADER_ZINDEX = new FixedZIndex(10);
    const sheetZIndex = new CompositeZIndex([HEADER_ZINDEX]);

    const getEvents = () => {
        directus.items('events').readByQuery({
            limit: -1,
            meta: '*',
            // @ts-ignore
            sort: ['-date']
        }).then(res => {
            // @ts-ignore
            updateEvents(res.data)
        })
    }

    const getEvent = (id: string) => {
        return directus.items('events').readOne(id)
    }

    const deleteEvent = async (id: string) => {
        await directus.items('events').deleteOne(id).then(() => {
            updatePanelOpen(false)
            getEvents()
        })
    }

    useEffect(() => {
        getEvents()
    }, []);

    const openPanel = (id: string) => {
        getEvent(id).then((res) => {
            // @ts-ignore
            updateEvent(res)
            updatePanelOpen(true)
        })
    }

    useEffect(() => {
        if (event) {
            setValue('date', event.date)
            setValue('list', event.list)
        }
    }, [event]);

    // @ts-ignore
    return (
        <Box padding={5}>
            <Flex justifyContent={'center'}>
                <Box marginBottom={5}>
                    <Button text={'Добавить расписание'} color={'red'} onClick={() => updatePanelOpenCreate(true)}/>
                </Box>
            </Flex>
            <Flex wrap gap={5}>
                {events.map((event) => {
                    return (
                        <Flex.Item key={event.id} flex={"grow"} flexBasis={'100%'}>
                            <Box padding={5} borderStyle={"sm"} rounding={2}>
                                <Flex>
                                    <Flex.Item flexBasis={'25%'}>
                                        <Text weight={"bold"}>Дата:</Text>
                                        <Heading
                                            size={"400"}>{format(new Date(event.date), "d MMMM yyyy (EEEE)")}</Heading>
                                        <Box marginTop={5}>
                                            <Button text={"Редактировать"} color={'gray'}
                                                    onClick={() => openPanel(event.id)}/>
                                        </Box>
                                    </Flex.Item>
                                    <Flex.Item flexBasis={'75%'}>
                                        <div style={{whiteSpace: "break-spaces"}}>
                                            <Text weight={"bold"}>Расписание:</Text>
                                            <Text>{event.list}</Text>
                                        </div>
                                    </Flex.Item>
                                </Flex>
                            </Box>
                        </Flex.Item>
                    )
                })}
            </Flex>
            {panelOpen && <Layer zIndex={sheetZIndex}>
                <OverlayPanel accessibilityLabel={"Редактирование расписния"} onDismiss={() => updatePanelOpen(false)}
                              heading={"Редактировать"}>
                    <form onSubmit={handleSubmit(onSubmitEditForm)}>
                        <Flex direction={'column'} gap={5}>
                            <Controller
                                control={control}
                                name='date'
                                render={({field}) => (
                                    <DatePicker
                                        localeData={ru as LocaleData}
                                        label={"Дата"}
                                        placeholder='Выберите дату'
                                        onChange={(date) => field.onChange(date.value?.toISOString() || '')}
                                        value={new Date(field.value)}
                                        id={'event-date'}/>
                                )}
                            />
                            <Controller
                                control={control}
                                name='list'
                                render={({field}) => (
                                    <TextArea id={'event-text'}
                                              value={field.value}
                                              placeholder={'Расписание'}
                                              label={'Расписание'}
                                              rows={15}
                                              onChange={(list) => field.onChange(list.value)}></TextArea>
                                )}/>
                            <Flex alignItems={'center'} justifyContent={'between'}>
                                <Button text={'Сохранить'} type={"submit"} color={'red'} role={"button"}/>
                                <Button text={'Удалить'} role={'button'} type={'button'} color={'transparent'}
                                        onClick={() => deleteEvent(event?.id || '')}/>
                            </Flex>
                        </Flex>
                    </form>
                </OverlayPanel>
            </Layer>}
            {panelOpenCreate && <Layer zIndex={sheetZIndex}>
                <OverlayPanel accessibilityLabel={"Добавление расписания"} heading={'Создание'}
                              onDismiss={() => updatePanelOpenCreate(false)}>
                    <form onSubmit={handleSubmitCreate(onSubmitCreateForm)}>
                        <Flex direction={'column'} gap={5}>
                            <Controller
                                control={controlCreate}
                                name='date'
                                render={({field}) => (
                                    <DatePicker
                                        localeData={ru as LocaleData}
                                        label={"Дата"}
                                        placeholder='Выберите дату'
                                        onChange={(date) => field.onChange(setHours(new Date(date.value || ''), 12).toISOString() || '')}
                                        id={'event-date'}/>
                                )}
                            />
                            <Controller
                                control={controlCreate}
                                name='list'
                                render={({field}) => (
                                    <TextArea id={'event-text'}
                                              placeholder={'Расписание'}
                                              label={'Расписание'}
                                              rows={15}
                                              onChange={(list) => field.onChange(list.value)}></TextArea>
                                )}/>
                            <Flex alignItems={'center'} justifyContent={'between'}>
                                <Button text={'Создать'} type={"submit"} color={'red'} role={"button"}/>
                            </Flex>
                        </Flex>
                    </form>
                </OverlayPanel>
            </Layer>}
        </Box>
    );
};

export default Events;
