import React, {useEffect, useState} from 'react';
import {Box, SegmentedControl, Table, Text, Label, Checkbox, Spinner, Button, Flex} from "gestalt";
import {directus} from "../utils/directus";
import {NotesCategoriesData, NotesData} from "../../@types";
import {chain, find} from "lodash"
import {format} from "date-fns";
import {utils, writeFileXLSX} from "xlsx";

type Group<T> = T[];

interface Dictionary<T> {
    [Key: string]: T[];
}

const statusList = [
    {
        id: 'pending',
        name: 'Ожидает оплаты'
    },
    {
        id: 'paid',
        name: 'Оплачено'
    },
    {
        id: 'canceled',
        name: 'Отменено'
    },
    {
        id: 'done',
        name: 'Выполненно'
    }
]

// @ts-ignore
const HeaderRow = ({id}) => {
    return (
        <Table.Header>
            <Table.Row>
                {/*<Table.HeaderCell>*/}
                {/*    <Box display="visuallyHidden">*/}
                {/*        <Label htmlFor={id}>Not all checkboxes are checked</Label>*/}
                {/*    </Box>*/}
                {/*    <Checkbox id={id} onChange={() => {*/}
                {/*    }} indeterminate size="sm"/>*/}
                {/*</Table.HeaderCell>*/}
                {['Дата', 'Тип записок', 'Имена', 'Статус'].map((title) => (
                    <Table.HeaderCell key={title}>
                        <Text weight={'bold'} align={'start'}>
                            {title}
                        </Text>
                    </Table.HeaderCell>
                ))}
            </Table.Row>
        </Table.Header>
    );
}

// @ts-ignore
const BaseRow = ({id, checked, status, date_created, names, category, categories}) => {
    return (
        <Table.Row>
            {/*<Table.Cell>*/}
            {/*<Checkbox*/}
            {/*    id={`${id.replace(/ /g, '_').replace(/'/g, '')}_${text*/}
            {/*        .replace(/ /g, '_')*/}
            {/*        .replace(/'/g, '')}`}*/}
            {/*    onChange={() => {*/}
            {/*    }}*/}
            {/*    size="sm"*/}
            {/*    checked={checked}*/}
            {/*/>*/}
            {/*</Table.Cell>*/}
            <Table.Cell>
                <Text>{format(new Date(date_created), 'dd MMMM yyyy')}</Text>
            </Table.Cell>
            <Table.Cell>
                <Text>{find(categories.data, {id: category}).name}</Text>
            </Table.Cell>
            <Table.Cell>
                {/*@ts-ignore*/}
                {names.map(item => {
                    return (
                        <Text>{item.name}</Text>
                    )
                })}
            </Table.Cell>
            <Table.Cell>
                {/*@ts-ignore*/}
                <Text>{find(statusList, {id: status})?.name}</Text>
            </Table.Cell>
        </Table.Row>
    );
}

const Treby = () => {
    const [itemIndex, setItemIndex] = useState<number>(0);
    const [notes, updateNotes] = useState<NotesData | null>(null)
    const [categories, updateCategories] = useState<NotesCategoriesData | null>(null)
    const [loading, updateLoading] = useState<boolean>(true)

    const [status, updateStatus] = useState<'paid' | 'done'>('paid')

    const items = [
        {
            id: 'paid',
            label: 'Новые'
        },
        {
            id: 'done',
            label: 'Выполненные'
        }
    ];

    useEffect(() => {
        updateLoading(true)
        directus.items('notes_categories').readByQuery({
            limit: -1,
            fields: 'id,name'
        }).then((data) => {
            // @ts-ignore
            updateCategories(data)

            directus.items('notes').readByQuery({
                limit: -1,
                // @ts-ignore
                sort: ['-date_created'],
                filter: {
                    status: {
                        _eq: status
                    }
                }
            }).then((data) => {
                // @ts-ignore
                updateNotes(data)
                updateLoading(false)
            })
        }).catch(() => {
            
        })
    }, [])

    function groupBy<T extends object>(array: T[], key: keyof T): Group<T>[] {
        let groups: Dictionary<T> = array.reduce((result, currentValue) => {
            let groupKey = String(currentValue[key]);
            if (!result[groupKey]) {
                result[groupKey] = [];
            }
            result[groupKey].push(currentValue);
            return result;
        }, {} as Dictionary<T>);

        return Object.values(groups);
    }

    const exportData = async () => {
        const formatData = notes?.data.map((item) => {
            return {
                // @ts-ignore
                category: find(categories?.data, {id: item.category}).name,
                names: item.names.map(({name}) => name).join(', '),
                status: find(statusList, {id: item.status})?.name,
                date_created: format(new Date(item.date_created), 'dd MMMM yyyy') || '',
                // @ts-ignore
                date_paid: format(new Date(item.date_paid), 'dd MMMM yyyy') || ''
            }
        })
        const pages = groupBy(formatData || [], 'category')
        const workbook = utils.book_new();
        pages.map((cat, i) => {
            const worksheet = utils.json_to_sheet(cat)
            utils.sheet_add_aoa(worksheet, [["Название", "Имена", "Статус", "Дата создания", "Дата оплаты"]], {origin: "A1"})
            utils.book_append_sheet(workbook, worksheet, `Лист ${i + 1}`)
        })
        // @ts-ignore
        await directus.items('notes').updateMany(notes?.data.map((item) => item.id), {
            status: 'done'
        }).then(() => {
            writeFileXLSX(workbook, "Zapiski.xlsx")
        })
    }

    useEffect(() => {
        updateLoading(true)
        directus.items('notes').readByQuery({
            limit: -1,
            // @ts-ignore
            sort: ['-date_created'],
            filter: {
                status: {
                    _eq: status
                }
            }
        }).then((data) => {
            // @ts-ignore
            updateNotes(data)
            updateLoading(false)
        })
    }, [status])

    const tableID = "Treby";

    return (
        <Box padding={5}>
            <Box margin={'auto'} maxWidth={'400px'}>
                <Flex width={'100%'} direction={'column'} justifyContent={'center'} gap={5}>
                    <SegmentedControl
                        items={items.map((item) => {
                            return item.label
                        })}
                        selectedItemIndex={itemIndex}
                        onChange={({activeIndex}) => {
                            setItemIndex(activeIndex)
                            // @ts-ignore
                            updateStatus(items[activeIndex].id)
                        }}
                    />
                    <Flex justifyContent={'center'}>
                        {(status == 'paid' && notes?.data.length) ?
                            <Button text={'Выгрузить записки'} color={'red'} onClick={exportData}/> : null}
                    </Flex>
                </Flex>
            </Box>
            <Box margin={'auto'} paddingY={5} maxWidth={'1280px'}>
                {!loading ? <Table borderStyle={'sm'} accessibilityLabel={'Требы'}>
                    <HeaderRow id={tableID}/>
                    <Table.Body>
                        {notes?.data.map(({id, date_created, category, names, status}) => {
                            return (
                                <BaseRow
                                    key={id}
                                    id={tableID}
                                    date_created={date_created}
                                    category={category}
                                    names={names}
                                    status={status}
                                    checked={false}
                                    categories={categories}
                                />
                            )
                        })}
                    </Table.Body>
                </Table> : <Spinner show/>}
            </Box>
        </Box>
    );
};

export default Treby;