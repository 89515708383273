import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom'
import {directus} from "../utils/directus";

const PrivateRoutes = () => {
    const [auth, updateAuth] = useState<boolean>(true)

    useEffect(() => {
        const me = directus.users.me.read().then((data) => {
            const { id } = data
            if (id) {
                updateAuth(true)
            } else {
                updateAuth(false)
            }
        }).catch(() => {
            updateAuth(false)
        })
    }, [])

    return (
        auth ? <Outlet/> : <Navigate to='/auth'/>
    );
};

export default PrivateRoutes;
